import * as React from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { Link } from "gatsby";

export default function Contact() {
    return (
        <>
            <Seo title="FAQS" />
            <BrandHeading title="frequently asked questions" />
            <section className="flex flex-wrap justify-evenly">
                <div className="max-w-3xl mx-auto flex flex-col gap-8">
                    <div>
                        <a href="#ALLERGIES" id="ALLERGIES" className="font-ovo text-2xl tracking-wider text-gray-900 mb-1">ALLERGIES</a>
                        <h3 className="font-ovo text-sm md:text-base tracking-wider text-gray-900 mb-3 md:mb-4">HOW DO YOU PREPARE YOUR GLUTEN-FRIENDLY AND VEGAN-FRIENDLY PRODUCTS?</h3>

                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray">
                            We prepare our no added gluten and vegan products following procedures to prevent allergen cross-contamination. 
                            We use no additional gluten, eggs or dairy ingredients and have designated utensils when making our no added gluten and vegan products. 
                            However, while we strive to avoid cross-contamination in any way possible, we would like to emphasise that we do handle products containing 
                            <span className="italic text-black"> gluten, dairy, eggs, nuts and soy</span> in our kitchen. 
                            Please be advised that we cannot guarantee the effects of our no added gluten and vegan products to those who are allergic and have high sensitivities to gluten and dairy.
                        </p>
                    </div>
                   
                    <div>
                        <a href="#DELIVERY" id="DELIVERY" className="font-ovo text-2xl tracking-wider text-gray-900 mb-1">DELIVERY</a>
                        <h3 className="font-ovo text-sm md:text-base tracking-wider text-gray-900 mb-3 md:mb-4">WHICH AREAS DO YOU COVER?</h3>

                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray">
                            We are currently delivering to all suburbs in Auckland. Our delivery fee is based on how far in kilometres your delivery address is from our North Shore kitchen.
                        </p>
                    </div>
                    
                    <div>
                        <a href="#TRANSPORTATION" id="TRANSPORTATION" className="font-ovo text-2xl tracking-wider text-gray-900 mb-1">TRANSPORTATION</a>
                        <h3 className="font-ovo text-sm md:text-base tracking-wider text-gray-900 mb-3 md:mb-4">WHAT IS THE SAFEST WAY TO TRANSPORT MY CAKE?</h3>

                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray mb-4">
                            <p className="mb-4">Placing the cake on a flat surface is ideal so anywhere on the floor or in the boot of your car is recommended. 
                               You should prepare your vehicle so it is clean and free of items that could roll or slide into the cake.</p>
                            <p>Please remember that you are responsible for the safe transportation of your cake.
                               We are no longer liable or responsible for your order once it has left our kitchen and do not provide s if any mishap or accidental damage occurs in transit. 
                               We do everything in our power to provide a well-structured cake, but please note that cakes are <span class="italic">very fragile </span>– <span class="italic">drive carefully and slowly</span>.</p>
                        </p>
                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray">
                            You are more than welcome to collect your cakes from our kitchen. However, if your cake has two-tiers or more, we strongly recommend opting for delivery as it is a more complex and delicate job to handle.
                        </p>
                    </div>

                    <div>
                        <a href="#ORDERCHANGES" id="ORDERCHANGES" className="font-ovo text-2xl tracking-wider text-gray-900 mb-1">ORDER CHANGES</a>
                        <h3 className="font-ovo text-sm md:text-base tracking-wider text-gray-900 mb-3 md:mb-4">CAN I STILL CHANGE MY ORDER AFTER I'VE PLACED IT?</h3>

                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray mb-3">
                            For <span class="italic">custom cake orders</span>, we are unable to make any changes after your invoice has been sent. We always make sure that every detail in the design of your cake is discussed completely with you and finalised beforehand.
                        </p>
                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray mb-3">
                            For <span class="italic">next day orders</span>, we can only make changes to your order before 1pm on the day you placed your order.
                        </p>
                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray">
                            Please contact us as soon as possible as we need at least 48 hours' notice to accommodate any order changes.
                        </p>
                    </div>
                    
                    <div>
                        <a href="#CANCELLATIONPOLICY" id="CANCELLATIONPOLICY" className="font-ovo text-2xl tracking-wider text-gray-900 mb-1">CANCELLATION POLICY</a>
                        <h3 className="font-ovo text-sm md:text-base tracking-wider text-gray-900 mb-3 md:mb-4">CAN I STILL CANCEL MY ORDER AND GET A REFUND?</h3>

                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray">
                            Please refer to our Refund Policy <Link to='/refund-policy' className="text-pas-orange underline underline-offset-4 decoration-pas-peach-darkest hover:decoration-2">here</Link>.
                        </p>
                    </div>
                   
                    <div>
                        <a href="#SERVINGSIZES" id="SERVINGSIZES" className="font-ovo text-2xl tracking-wider text-gray-900 mb-1">SERVING SIZES</a>
                        <h3 className="font-ovo text-sm md:text-base tracking-wider text-gray-900 mb-3 md:mb-4">HOW MANY PORTIONS CAN I GET FROM MY CAKE?</h3>
                        
                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray mb-5">
                            If you would like to customise the height and layers of your cake, please <Link to='/contact' className="text-pas-orange underline underline-offset-4 decoration-pas-peach-darkest hover:decoration-2">contact</Link> us and we will do our best to accommodate your request.
                            Otherwise, our serving sizes for each cake category are below:
                        </p>
                        <div className="flex flex-col sm:flex-row gap-x-2">
                            <span className="text-left text-sm text-black tracking-wider md:tracking-[.06em] leading-5 md:leading-[1.6rem]">PETITE CAKES</span>
                            <span className="text-left text-xs text-black tracking-wider md:tracking-[.06em] leading-5 md:leading-[1.6rem] mt-1 md:mt-0">(rectangle serve = 1 x 2 x 4 inches)</span>
                        </div>
                        <p className="font-gotu text-left text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray mb-5 mt-1">These are our standard <span class="italic">short cakes</span> which are approx. 4 inches tall with 3 layers :</p>
                            <PricingEntry size={"6 INCH"} description={"8 to 10 triangle serves / 14 rectangle serves"} />
                            <PricingEntry size={"8 INCH"} description={"12 to 14 triangle serves / 26 rectangle serves"} />
                        
                        <div className="flex flex-col sm:flex-row gap-x-2 mt-7">
                            <span className="text-left text-sm text-black tracking-wider md:tracking-[.06em] leading-5 md:leading-[1.6rem]">CELEBRATION & BESPOKE CAKES</span>
                            <span className="text-left text-xs text-black tracking-wider md:tracking-[.06em] leading-5 md:leading-[1.6rem] mt-1 md:mt-0">(dessert serve = 2 x 1 x 4 inches | coffee serve = 2 x 1 x 2.5 inches)</span>
                        </div>
                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray mb-5 mt-1">These are our standard <span class="italic">tall cakes</span> which are approx. 8 inches tall with 6 layers :</p>
                            <PricingEntry size={"4 INCH"} description={"12 dessert serves / 18 coffee serves"} />
                            <PricingEntry size={"6 INCH"} description={"28 dessert serves / 42 coffee serves"} />
                            <PricingEntry size={"8 INCH"} description={"52 dessert serves / 78 coffee serves"} />
                            <PricingEntry size={"TWO-TIER 4 + 6 INCH"} description={"40 dessert serves / 60 coffee serves"} />
                            <PricingEntry size={"TWO-TIER 6 + 8 INCH"} description={"80 dessert serves / 120 coffee serves"} />
                            <PricingEntry size={"THREE-TIER 4 + 6 + 8 INCH"} description={"92 dessert serves / 138 coffee serves"} />
                            <PricingEntry size={"THREE-TIER 6 + 8 + 10 INCH"} description={"156 dessert serves / 234 coffee serves"} />
                            <PricingEntry size={"FOUR-TIER 4 + 6 + 8 + 10 INCH"} description={"168 dessert serves / 252 coffee serves"} />

                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray mt-5">
                           Please note that while these numbers are a useful guide, the actual number of serves will depend on the way your cake is cut on the day.
                        </p>
                    </div>
                    <div>
                        <a href="#STORAGE" id="STORAGE" className="font-ovo text-2xl tracking-wider text-gray-900 mb-1">STORAGE</a>
                        <h3 className="font-ovo text-sm md:text-base tracking-wider text-gray-900 mb-3 md:mb-4">HOW SHOULD I KEEP MY CAKE & HOW LONG CAN IT LAST?</h3>

                        <p className="font-gotu text-justify text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-[1.4rem] md:leading-[1.7rem] text-pas-gray">
                            All cakes should be stored in the refrigerator (5°C) and taken out to be brought to room temperature half an hour prior to serving. Our cakes can last in the fridge for up to 3 days.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}

function PricingEntry({ size, description }) {
    return (
        <div className="flex flex-col sm:flex-row mb-1">
            <span className="basis-full sm:basis-5/12 px-1 sm:pr-6 text-center sm:text-right 
            font-gotu text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-6 md:leading-[1.7rem] text-pas-gray">
                {size}
            </span>
            <span className="basis-full sm:basis-6/12 px-1 sm:pl-6 text-center sm:text-left 
            font-gotu text-[.78rem] md:text-[0.80rem] tracking-wider md:tracking-[.07em] leading-6 md:leading-[1.7rem] text-pas-gray">
                {description}
            </span>
        </div>
    )
}
